<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center bg-white bg-dark">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Login</h4>
                  <p>Welcome back, please login to your account.</p>
                </div>

                <vs-input
                  name="username"
                  icon="icon icon-user"
                  icon-pack="feather"
                  label-placeholder="username"
                  v-model="username"
                  class="w-full no-icon-border"
                />
                <span class="text-danger text-sm">{{ errors.first('username') }}</span>

                <vs-input
                  data-vv-validate-on="blur"
                  v-validate="'required|min:6|max:10'"
                  type="password"
                  name="password"
                  icon="icon icon-lock"
                  icon-pack="feather"
                  label-placeholder="Password"
                  v-model="password"
                  class="w-full mt-6 no-icon-border"
                />
                <span class="text-danger text-sm">{{ errors.first('password') }}</span>

                <div class="flex flex-wrap justify-between my-5">
                  <!-- <vs-checkbox v-model="checkbox_remember_me" class="mb-3">Remember Me</vs-checkbox>
                  <router-link to="/pages/forgot-password">Forgot Password?</router-link>-->
                </div>
                <vs-button type="border" @click="registerUser">Register</vs-button>
                <!-- <vs-button class="float-right" :disabled="!validateForm" @click="login">Login</vs-button> -->
                <vs-button class="float-right" @click="login">Login</vs-button>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      checkbox_remember_me: false
    };
  },
  computed: {
    // validateForm() {
    //   // return !this.errors.any() && this.username != "" && this.password != "";
    // }
  },
  methods: {
    login() {
      this.$http
        .post(this.$store.state.apiURL + "/api/user/login", {
          username: this.username,
          password: this.password
        })
        .then(response => {
          console.log(response.status);
          localStorage.setItem("authToken", response.data.token);
          var jwt_decoded = this.parseJwt(response.data);
          console.log("this is jwt decoded >>. ", jwt_decoded);
          this.$router.push("/");
        })
        .catch(function(error) {
          console.log(error);
        });
    }

    // const payload = {
    //   checkbox_remember_me: this.checkbox_remember_me,
    //   userDetails: {
    //     username: this.username,
    //     password: this.password
    //   },
    //   notify: this.$vs.notify
    // };
    // this.$store.dispatch("auth/loginAttempt", payload);
    // this.$http
    //   .post(this.$store.state.apiURL + "/api/user/login", {
    //     username: payload.userDetails.username,
    //     password: payload.userDetails.password
    //   })
    //   .then(
    //     response => {
    //       this.$router.push(this.$router.currentRoute.query.to || "/");
    //       // commit("UPDATE_AUTHENTICATED_USER", response.data);
    //       localStorage.setItem("userInfo", JSON.stringify(response.data));
    //       localStorage.setItem("userRole", "admin");
    //       console.log(response);
    //     },
    //     err => {
    //       payload.notify({
    //         time: 2500,
    //         title: "Error",
    //         text: err.message,
    //         iconPack: "feather",
    //         icon: "icon-alert-circle",
    //         color: "danger"
    //       });
    //       console.log(err);
    //     }
    //   );
  },

  // loginAuth0() {
  //   if (this.$store.state.auth.isUserLoggedIn()) {
  //     this.notifyAlreadyLogedIn();
  //     return false;
  //   }
  //   this.$auth.login({ target: this.$route.query.to });
  // },

  // Google login
  // loginWithGoogle() {
  //   this.$store.dispatch("auth/loginWithGoogle", { notify: this.$vs.notify });
  // },

  // // Facebook login
  // loginWithFacebook() {
  //   this.$store.dispatch("auth/loginWithFacebook", {
  //     notify: this.$vs.notify
  //   });
  // },

  // // Twitter login
  // loginWithTwitter() {
  //   this.$store.dispatch("auth/loginWithTwitter", {
  //     notify: this.$vs.notify
  //   });
  // },

  // // Github login
  // loginWithGithub() {
  //   this.$store.dispatch("auth/loginWithGithub", { notify: this.$vs.notify });
  // },

  notifyAlreadyLogedIn() {
    this.$vs.notify({
      title: "Login Attempt",
      text: "You are already logged in!",
      iconPack: "feather",
      icon: "icon-alert-circle",
      color: "warning"
    });
  },
  registerUser() {
    if (this.$store.state.auth.isUserLoggedIn()) {
      this.notifyAlreadyLogedIn();
      return false;
    }
    this.$router.push("/pages/register");
  }
};
</script>

<style lang="scss">
#page-login {
  .social-login {
    .bg-facebook {
      background-color: #1551b1;
    }
    .bg-twitter {
      background-color: #00aaff;
    }
    .bg-google {
      background-color: #1551b1;
    }
    .bg-github {
      background-color: #333;
    }
  }
}
</style>
